import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { ToastContainer } from 'react-toastify';
import isEmpty from 'lodash/isEmpty';
import { setNetworkStatus } from '../../redux/modules/info';
import config from '../../config';
import globalConst from '../../constants';
import {
  getProfile,
  getUserType,
  getIsExternal,
  getIsExternalBlocked,
  getIsInternalBlocked,
  getIsInternalBlockedSwzzorg,
  getIsInternalBlockedFletcher,
  getIsYzorgManagers,
  getExternalExcellentFlex,
  getBrandColor,
  getBrandTextColor,
  getFeatureIsTimeTracking,
  getFeatureIsTimeTrackingSign,
  getFeatureIsImportingShifts,
  getFeatureIsBranding,
  getFeatureIsRecurring,
  getFeatureIsExtendedReport,
  getFeatureIsOrtSchema,
  getFeatureIsSimpleOrtSchema,
  getFeatureIsInternalManagers,
  getFeatureIsExpenses,
  getFeatureIsExternalManagers,
  getFeatureIsMessaging,
  getFeatureIsCCemail,
  getFeatureIsManualApprove,
  getFeatureIsAdvancedFiltering,
  getFeatureIsCustomFields,
  getFeatureIsMultiRoles,
  getIsMaintenance,
  getNetworkStatus,
  getFeatureIsFull,
  getFeatureIsFullDays,
  getIsFreemium,
  getFeatureIsCalculated,
  getFeatureIsCopyShift,
  getFeatureIsWorkerCalendarView,
  getFeatureIsFavoriteWorkersList,
  getFeatureIsBlacklistWorkersList,
  getFeatureIsAdditionalWorkerStatuses,
  getFeatureIsStoreFilter,
  getFeatureIsAutoApprove,
  getFeatureIsGigCategorySettings,
  getFeatureIsReview,
  getFeatureIsPublicProfile,
  getFeatureIsNewDashboard,
  getFeatureIsOrgGroup,
  getFeatureIsBilling,
  getFeatureIsMultiBillingInfo,
  getFeatureIsMultiDays,
  getPreparedDefaultScheduleType,
  getFeatureIsAgencyFee,
  getAllowedAgencyFees,
  getAllowedUnavailabilities,
  getFeatureIsCreditInvoice,
  getFeatureIsSummaryInvoice,
  getFeatureIsSellingExpenses,
  getFeatureIsFeeInvoice,
  getFeatureIsOrgAttributesFilter,
  getFeatureIsPlacementEntities,
  getFeatureIsPlannerDistance,
  getFeatureIsManagerConfigurable,
  getDefaultOpenShift,
  getCanCreateOrg,
  getIsFeatureProtectedWorkersDocuments,
  getIsFeatureAgreements,
  getIsSigningDocuments,
  getFeatureIsProjects,
} from '../../services/selectors';
import {
  getAvailableLangs,
  getTranslates,
  getCurrentLang,
} from '../../services/translateSelector';
// import { showWarning } from '../../helpers/uiHelper';
import Preloader from '../../components/Preloader';
import { Maintenance } from '../../components/Maintenance/Maintenance';
import darkFavicon from '../../../theme/img/clevergig_logo_cmyk_element.png';
import whiteFavicon from '../../../theme/img/clevergig_logo_wit_element.png';
import faviconLF from '../../../theme/img/logisticForce/logo_header_LF.png';

const mapStateToProps = (state) => ({
  profile: getProfile(state),
  userType: getUserType(state),
  isExternal: getIsExternal(state),
  isExternalBlocked: getIsExternalBlocked(state),
  isInternalBlocked: getIsInternalBlocked(state),
  isInternalBlockedFletcher: getIsInternalBlockedFletcher(state),
  isInternalBlockedSwzzorg: getIsInternalBlockedSwzzorg(state),
  isExternalExcellentFlex: getExternalExcellentFlex(state),
  isYzorgManagers: getIsYzorgManagers(state),
  langs: getAvailableLangs(state),
  localization: getTranslates(state),
  currentLang: getCurrentLang(state),
  brandColor: getBrandColor(state),
  brandTextColor: getBrandTextColor(state),
  networkStatus: getNetworkStatus(state),
  featureIsTimeTracking: getFeatureIsTimeTracking(state),
  featureIsAgencyFee: getFeatureIsAgencyFee(state),
  featureIsTimeTrackingSign: getFeatureIsTimeTrackingSign(state),
  featureIsImportingShifts: getFeatureIsImportingShifts(state),
  featureIsBranding: getFeatureIsBranding(state),
  featureIsRecurring: getFeatureIsRecurring(state),
  featureIsExtendedReport: getFeatureIsExtendedReport(state),
  featureIsOrtSchema: getFeatureIsOrtSchema(state),
  featureIsSimpleOrtSchema: getFeatureIsSimpleOrtSchema(state),
  featureIsInternalManagers: getFeatureIsInternalManagers(state),
  featureIsExpenses: getFeatureIsExpenses(state),
  featureIsExternalManagers: getFeatureIsExternalManagers(state),
  featureIsMessaging: getFeatureIsMessaging(state),
  featureIsCCemail: getFeatureIsCCemail(state),
  featureIsManualApprove: getFeatureIsManualApprove(state),
  featureIsAdvancedFiltering: getFeatureIsAdvancedFiltering(state),
  featureIsCustomFields: getFeatureIsCustomFields(state),
  featureIsMultiRoles: getFeatureIsMultiRoles(state),
  featureIsFull: getFeatureIsFull(state),
  featureIsFullDays: getFeatureIsFullDays(state),
  featureIsCopyShift: getFeatureIsCopyShift(state),
  featureIsWorkerCalendarView: getFeatureIsWorkerCalendarView(state),
  featureIsFavoriteWorkersList: getFeatureIsFavoriteWorkersList(state),
  featureIsBlacklistWorkersList: getFeatureIsBlacklistWorkersList(state),
  featureIsAdditionalWorkerStatuses: getFeatureIsAdditionalWorkerStatuses(state),
  featureIsStoreFilter: getFeatureIsStoreFilter(state),
  featureIsAutoApprove: getFeatureIsAutoApprove(state),
  featureIsCalculated: getFeatureIsCalculated(state),
  featureIsGigCategorySettings: getFeatureIsGigCategorySettings(state),
  featureIsReview: getFeatureIsReview(state),
  featureIsPublicProfile: getFeatureIsPublicProfile(state),
  featureIsNewDashboard: getFeatureIsNewDashboard(state),
  featureIsOrgGroup: getFeatureIsOrgGroup(state),
  featureIsBilling: getFeatureIsBilling(state),
  featureIsMultiBillingInfo: getFeatureIsMultiBillingInfo(state),
  featureIsMultiDays: getFeatureIsMultiDays(state),
  featureIsCreditInvoice: getFeatureIsCreditInvoice(state),
  featureIsSummaryInvoice: getFeatureIsSummaryInvoice(state),
  featureIsSellingExpenses: getFeatureIsSellingExpenses(state),
  featureIsFeeInvoice: getFeatureIsFeeInvoice(state),
  featureIsOrgAttributesFilter: getFeatureIsOrgAttributesFilter(state),
  featureIsPlacementEntities: getFeatureIsPlacementEntities(state),
  featureIsPlannerDistance: getFeatureIsPlannerDistance(state),
  featureIsManagerConfigurable: getFeatureIsManagerConfigurable(state),
  featureIsProjects: getFeatureIsProjects(state),
  defaultOpenShift: getDefaultOpenShift(state),
  isFeatureProtectedWorkersDocuments: getIsFeatureProtectedWorkersDocuments(state),
  isFreemium: getIsFreemium(state),
  isMaintenance: getIsMaintenance(state),
  defScheduleType: getPreparedDefaultScheduleType(state),
  allowedAgencyFees: getAllowedAgencyFees(state),
  allowedUnavailabilities: getAllowedUnavailabilities(state),
  canCreateOrg: getCanCreateOrg(state),
  isFeatureAgreements: getIsFeatureAgreements(state),
  isSigningDocuments: getIsSigningDocuments(state),
});

const dispatchToProps = (dispatch) => ({
  setNetworkStatus: (status) => dispatch(setNetworkStatus(status)),
});

export class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      favicon: darkFavicon
    };
  }

  getChildContext() {
    return {
      localization: this.props.localization,
      currentLang: this.props.currentLang,
      langs: this.props.langs,
      profile: this.props.profile,
      userType: this.props.userType,
      isExternal: this.props.isExternal,
      isExternalBlocked: this.props.isExternalBlocked,
      isInternalBlocked: this.props.isInternalBlocked,
      isInternalBlockedFletcher: this.props.isInternalBlockedFletcher,
      isInternalBlockedSwzzorg: this.props.isInternalBlockedSwzzorg,
      isExternalExcellentFlex: this.props.isExternalExcellentFlex,
      isYzorgManagers: this.props.isYzorgManagers,
      brandColor: this.props.brandColor,
      brandTextColor: this.props.brandTextColor,
      networkStatus: this.props.networkStatus,
      featureIsTimeTracking: this.props.featureIsTimeTracking,
      featureIsTimeTrackingSign: this.props.featureIsTimeTrackingSign,
      featureIsImportingShifts: this.props.featureIsImportingShifts,
      featureIsBranding: this.props.featureIsBranding,
      featureIsAgencyFee: this.props.featureIsAgencyFee,
      featureIsRecurring: this.props.featureIsRecurring,
      featureIsExtendedReport: this.props.featureIsExtendedReport,
      featureIsOrtSchema: this.props.featureIsOrtSchema,
      featureIsSimpleOrtSchema: this.props.featureIsSimpleOrtSchema,
      featureIsInternalManagers: this.props.featureIsInternalManagers,
      featureIsExpenses: this.props.featureIsExpenses,
      featureIsExternalManagers: this.props.featureIsExternalManagers,
      featureIsMessaging: this.props.featureIsMessaging,
      featureIsCCemail: this.props.featureIsCCemail,
      featureIsCalculated: this.props.featureIsCalculated,
      featureIsManualApprove: this.props.featureIsManualApprove,
      featureIsAdvancedFiltering: this.props.featureIsAdvancedFiltering,
      featureIsCustomFields: this.props.featureIsCustomFields,
      featureIsMultiRoles: this.props.featureIsMultiRoles,
      featureIsFull: this.props.featureIsFull,
      featureIsFullDays: this.props.featureIsFullDays,
      featureIsCopyShift: this.props.featureIsCopyShift,
      featureIsWorkerCalendarView: this.props.featureIsWorkerCalendarView,
      featureIsFavoriteWorkersList: this.props.featureIsFavoriteWorkersList,
      featureIsBlacklistWorkersList: this.props.featureIsBlacklistWorkersList,
      featureIsAdditionalWorkerStatuses: this.props.featureIsAdditionalWorkerStatuses,
      featureIsStoreFilter: this.props.featureIsStoreFilter,
      featureIsAutoApprove: this.props.featureIsAutoApprove,
      featureIsFeeInvoice: this.props.featureIsFeeInvoice,
      featureIsGigCategorySettings: this.props.featureIsGigCategorySettings,
      featureIsReview: this.props.featureIsReview,
      featureIsPublicProfile: this.props.featureIsPublicProfile,
      featureIsNewDashboard: this.props.featureIsNewDashboard,
      featureIsOrgGroup: this.props.featureIsOrgGroup,
      featureIsBilling: this.props.featureIsBilling,
      featureIsMultiBillingInfo: this.props.featureIsMultiBillingInfo,
      featureIsMultiDays: this.props.featureIsMultiDays,
      featureIsOrgAttributesFilter: this.props.featureIsOrgAttributesFilter,
      featureIsSummaryInvoice: this.props.featureIsSummaryInvoice,
      featureIsPlacementEntities: this.props.featureIsPlacementEntities,
      featureIsPlannerDistance: this.props.featureIsPlannerDistance,
      featureIsManagerConfigurable: this.props.featureIsManagerConfigurable,
      featureIsCreditInvoice: this.props.featureIsCreditInvoice,
      featureIsSellingExpenses: this.props.featureIsSellingExpenses,
      featureIsProjects: this.props.featureIsProjects,
      defaultOpenShift: this.props.defaultOpenShift,
      isFeatureProtectedWorkersDocuments: this.props.isFeatureProtectedWorkersDocuments,
      isFreemium: this.props.isFreemium,
      defScheduleType: this.props.defScheduleType,
      allowedAgencyFees: this.props.allowedAgencyFees,
      allowedUnavailabilities: this.props.allowedUnavailabilities,
      canCreateOrg: this.props.canCreateOrg,
      isFeatureAgreements: this.props.isFeatureAgreements,
      isSigningDocuments: this.props.isSigningDocuments,
    };
  }

  componentDidMount() {
    this.updateFavicon();
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', this.updateFavicon);
    const networkStatus = window.navigator.onLine
      ? globalConst.ONLINE_STATUS : globalConst.OFFLINE_STATUS;

    // set network connection
    this.props.setNetworkStatus(networkStatus);
    window.addEventListener('online', this.handleConnection);
    window.addEventListener('offline', this.handleConnection);
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.handleConnection);
    window.removeEventListener('offline', this.handleConnection);
  }

  updateFavicon = () => {
    const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
    const newFavicon = isDarkMode ? whiteFavicon : darkFavicon;
    if (newFavicon !== this.state.favicon) {
      this.setState({ favicon: newFavicon });
    }
  };

  /**
   * Handle connection
   * @param {object} event SyntheticEvent
   */
  handleConnection = (event) => {
    // const { localization: tr } = this.props;
    this.props.setNetworkStatus(event.type);

    if (event.type === globalConst.OFFLINE_STATUS) {
      // showWarning(tr.messages.internetConnection);
    }
  };

  render() {
    const {
      localization: tr,
      currentLang,
      children,
      isMaintenance,
    } = this.props;
    const {
      favicon
    } = this.state;

    if (isEmpty(tr)) {
      return (<Preloader isDefault={config.isLogisticForce} />);
    }

    if (isMaintenance) {
      return (<Maintenance tr={tr} />);
    }

    return (
      <div>
        <Helmet
          titleTemplate={config.isLogisticForce ? 'LogisticForce: %s' : 'clevergig: %s'}
          htmlAttributes={{ lang: currentLang }}
          title={tr.global.metaTitle}
          meta={config.app.head.meta}
        >
          {!config.isLogisticForce && (
            <link rel="icon" href={favicon} type="image/x-icon" />
          )}
          {!config.isLogisticForce && (
            <link rel="apple-touch-icon" href={favicon} type="image/x-icon" />
          )}
          {config.isLogisticForce && (
            <link rel="icon" href={faviconLF} type="image/x-icon" />
          )}
          {config.isLogisticForce && (
            <link rel="apple-touch-icon" href={faviconLF} type="image/x-icon" />
          )}
        </Helmet>
        {children}
        <ToastContainer />
      </div>
    );
  }
}

const featureProps = {
  allowedAgencyFees: PropTypes.number.isRequired,
  allowedUnavailabilities: PropTypes.number.isRequired,
  featureIsTimeTracking: PropTypes.bool.isRequired,
  featureIsAgencyFee: PropTypes.bool.isRequired,
  featureIsTimeTrackingSign: PropTypes.bool.isRequired,
  featureIsImportingShifts: PropTypes.bool.isRequired,
  featureIsBranding: PropTypes.bool.isRequired,
  featureIsRecurring: PropTypes.bool.isRequired,
  featureIsExtendedReport: PropTypes.bool.isRequired,
  featureIsOrtSchema: PropTypes.bool.isRequired,
  featureIsSimpleOrtSchema: PropTypes.bool.isRequired,
  featureIsInternalManagers: PropTypes.bool.isRequired,
  featureIsExpenses: PropTypes.bool.isRequired,
  featureIsExternalManagers: PropTypes.bool.isRequired,
  featureIsMessaging: PropTypes.bool.isRequired,
  featureIsCCemail: PropTypes.bool.isRequired,
  featureIsCalculated: PropTypes.bool.isRequired,
  featureIsManualApprove: PropTypes.bool.isRequired,
  featureIsAdvancedFiltering: PropTypes.bool.isRequired,
  featureIsCustomFields: PropTypes.bool.isRequired,
  featureIsMultiRoles: PropTypes.bool.isRequired,
  featureIsFull: PropTypes.bool.isRequired,
  featureIsFullDays: PropTypes.number.isRequired,
  featureIsCopyShift: PropTypes.bool.isRequired,
  featureIsWorkerCalendarView: PropTypes.bool.isRequired,
  featureIsFeeInvoice: PropTypes.bool.isRequired,
  featureIsFavoriteWorkersList: PropTypes.bool.isRequired,
  featureIsBlacklistWorkersList: PropTypes.bool.isRequired,
  featureIsAdditionalWorkerStatuses: PropTypes.bool.isRequired,
  featureIsStoreFilter: PropTypes.bool.isRequired,
  featureIsAutoApprove: PropTypes.bool.isRequired,
  featureIsGigCategorySettings: PropTypes.bool.isRequired,
  featureIsReview: PropTypes.bool.isRequired,
  featureIsPublicProfile: PropTypes.bool.isRequired,
  featureIsNewDashboard: PropTypes.bool.isRequired,
  featureIsOrgGroup: PropTypes.bool.isRequired,
  featureIsBilling: PropTypes.bool.isRequired,
  featureIsMultiBillingInfo: PropTypes.bool.isRequired,
  featureIsCreditInvoice: PropTypes.bool.isRequired,
  featureIsMultiDays: PropTypes.bool.isRequired,
  featureIsOrgAttributesFilter: PropTypes.bool.isRequired,
  featureIsSummaryInvoice: PropTypes.bool.isRequired,
  featureIsPlacementEntities: PropTypes.bool.isRequired,
  featureIsPlannerDistance: PropTypes.bool.isRequired,
  featureIsManagerConfigurable: PropTypes.bool.isRequired,
  featureIsSellingExpenses: PropTypes.bool.isRequired,
  featureIsProjects: PropTypes.bool.isRequired,
  defaultOpenShift: PropTypes.bool.isRequired,
  isFeatureProtectedWorkersDocuments: PropTypes.bool.isRequired,
  isFeatureAgreements: PropTypes.bool.isRequired,
  isSigningDocuments: PropTypes.bool.isRequired,
  canCreateOrg: PropTypes.bool.isRequired,
};
App.childContextTypes = {
  currentLang: PropTypes.string.isRequired,
  localization: PropTypes.object.isRequired,
  langs: PropTypes.array.isRequired,
  profile: PropTypes.object.isRequired,
  userType: PropTypes.string.isRequired,
  isExternal: PropTypes.bool.isRequired,
  isExternalBlocked: PropTypes.bool.isRequired,
  isInternalBlocked: PropTypes.bool.isRequired,
  isInternalBlockedFletcher: PropTypes.bool.isRequired,
  isInternalBlockedSwzzorg: PropTypes.bool.isRequired,
  isExternalExcellentFlex: PropTypes.bool.isRequired,
  isYzorgManagers: PropTypes.bool.isRequired,
  brandColor: PropTypes.string.isRequired,
  brandTextColor: PropTypes.string.isRequired,
  networkStatus: PropTypes.string.isRequired,
  defScheduleType: PropTypes.string.isRequired,
  isFreemium: PropTypes.bool.isRequired,
  ...featureProps,
};
App.propTypes = {
  currentLang: PropTypes.string.isRequired,
  localization: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired,
  brandColor: PropTypes.string.isRequired,
  networkStatus: PropTypes.string.isRequired,
  isMaintenance: PropTypes.bool.isRequired,
  defScheduleType: PropTypes.string.isRequired,
  isFreemium: PropTypes.bool.isRequired,
  setNetworkStatus: PropTypes.func.isRequired,
  ...featureProps,
};

export default connect(mapStateToProps, dispatchToProps)(App);
